<template>
  <el-dialog
    class="editDialog"
    :append-to-body="true"
    :width="width"
    :visible.sync="showEditDialog"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    @close="closeFn"
    :show-close="true"
  >
    <slot name="title" slot="title"> </slot>

    <slot name="content"> </slot>

    <slot name="footer" slot="footer" v-if="saveShow">
      <el-button type="success" :loading="loading" @click="$emit('submit')">{{
        submitBtnText
      }}</el-button>
      <el-button type="warning" plain @click="$emit('update:isShow', false)">取消</el-button>
    </slot>
    <slot name="footer" slot="footer" v-else>
      <el-button type="success" :loading="loading" @click="$emit('update:isShow', false)"
        >返回</el-button
      >
    </slot>
  </el-dialog>
</template>
<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    isShow: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '50%',
    },
    saveShow: {
      type: Boolean,
      default: true,
    },
    isReturn: {
      type: Boolean,
      default: false,
    },
    submitBtnText: {
      type: String,
      default: '保存',
    },
  },
  name: 'EditDialog',
  data() {
    return {
      showEditDialog: false,
      formData: {},
    }
  },
  watch: {
    isShow: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        // this.$emit('update:loading', false);
        this.showEditDialog = newVal
      },
    },
  },
  methods: {
    closeFn() {
      if (this.isReturn) {
        this.$emit('closeFn')
      } else {
        this.$emit('update:isShow', false)
      }
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';

.editDialog {
  /deep/ .el-dialog {
    .el-dialog__header {
      /*border-bottom:#ddd solid 1px;*/
      /*box-shadow: 0 0 10px $blue;*/
      .title {
        color: $blue;
      }
    }
    .el-dialog__body {
      padding: 20px 10px;
      .el-transfer-panel {
        width: calc(50% - 91px);
      }
      .content {
        max-height: calc(90vh - 164px);
        overflow: auto;
      }
    }
    .el-dialog__footer {
      text-align: center;
      .el-button {
        min-width: 120px;
        margin: 0 25px;
      }
    }
  }
}
</style>
